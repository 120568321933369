<template>
  <div>
    <leetcode-article />
  </div>
</template>

<script>
import article from './91-article'

export default {
  components: {
    'leetcode-article': article
  },
  data() {
    return {}
  },
  methods: {},
  watch: {}
}
</script>

<style lang="less" scoped></style>
